import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import About from "./style";
import Video from "~components/VideoModal";
import StyledButton from "~sections/landingpage/components/Botones";
import ValuaBotIMG from "../../../../assets/image/landing/valuaBot/ValuaBotVideo.png";
export default function AboutSection() {
  return (
    <About>
      <Container>
        {/* about-us Content */}
        <Row className="align-items-center justify-content-center">
          <Col
            col="12"
            className="col-xxl-5 offset-xxl-1 col-lg-5 col-md-8 col-xs-10"
          >
              <About.Content mt="40px" mtLG="0" mb="50px" mbLG="0" mlLG="30px">
                <h2>ValuaBot</h2>
                <p
                  style={{
                    marginTop: "30px",
                    lineHeight: "1.77",
                    fontSize: "18px",
                  }}
                >
                  ValuaBot es una herramienta digital avanzada diseñada
                  específicamente para evaluar y tasar artículos, proporcionando
                  a los usuarios precios estimados tanto para la compra como
                  para la venta.
                </p>
                <About.ButtonGroup mt="25px">
                  <StyledButton
                    text="Pruebalo tu mismo"
                    backGroundColor="linear-gradient(to right, rgb(208, 36, 116), rgb(163, 82, 175), rgb(95, 82, 212)) !important"
                    hoverBgColor="linear-gradient(to right, rgb(95, 82, 212), rgb(163, 82, 175), rgb(208, 36, 116)) !important"
                    href="https://www.valuabot.com/"
                    //onClick={() => setModalShow(true)}
                  />
                </About.ButtonGroup>
              </About.Content>
          </Col>
          <Col xs="12" className="col-xl-6 col-lg-6 col-md-8 col-xs-10">
              <About.Image>
                <img
                  src={ValuaBotIMG}
                  style={{ width: "100%", height: "100%" }}
                  alt="ValuaBot"
                ></img>
                <Video id="LWZ7iytIA6k" className="video-btn sonar-emitter">
                  {" "}
                  <i className="fa fa-play" />{" "}
                </Video>
              </About.Image>
          </Col>
        </Row>
        {/* <ValuaBot
          show={modalShow}
          onHide={() => setModalShow(false)}
        /> */}
        {/*/ .about-us Content */}
      </Container>
    </About>
  );
}
